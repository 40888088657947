import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class AngularDateHttpInterceptor implements HttpInterceptor {
  // Migrated from AngularJS https://raw.githubusercontent.com/Ins87/angular-date-interceptor/master/src/angular-date-interceptor.js
  iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const object = req.body;
    this.dateToString(object);
    const cloned: HttpRequest<any> = req.clone({body: object});
    return next.handle(cloned).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const body = event.body;
          this.stringToDate(body);
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
          }
        }
      }),
    );
  }

  isIso8601(value: any) {
    if (!value) return false;
    return this.iso8601.test(value);
  }

  stringToDate(body: any) {
    if (!body) return body;

    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this.isIso8601(value)) {
        body[key] = new Date(value);
      } else if (typeof value === 'object') {
        this.stringToDate(value);
      }
    }
  }

  dateToString(body: any) {
    if (!body) return body;

    if (typeof body !== 'object') {
      return body;
    }

    const keys = Object.keys(body);
    for (const key of keys) {
      const value = body[key];
      if (value instanceof Date) {
        body[key] = this.format(value);
      } else if (typeof value === 'object') {
        this.dateToString(value);
      }
    }
  }

  format(date: Date): string {
    {
      const dayNumber = date.getUTCDate();
      const dayString = dayNumber < 10 ? `0${dayNumber}` : `${dayNumber}`

      let monthNumber = date.getUTCMonth() + 1;
      const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`

      let yearNumber = date.getUTCFullYear()
      let yearString = '' + yearNumber

      let hoursNumber = date.getUTCHours()
      let hoursString = hoursNumber < 10 ? `0${hoursNumber}` : `${hoursNumber}`

      let minutesNumber = date.getUTCHours()
      let minutesString = minutesNumber < 10 ? `0${minutesNumber}` : `${minutesNumber}`

      let secondsNumber = date.getUTCHours()
      let secondsString = secondsNumber < 10 ? `0${secondsNumber}` : `${secondsNumber}`

      const iso = `${yearString}-${monthString}-${dayString}T${hoursString}:${minutesString}:${secondsString}+00:00`;
      console.log({iso});
      return iso
    }
  }

}

import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {RedirectComponent} from './redirect.component';
import {LogoutComponent} from './logout.component';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from './shared/shared.module';
import {SignInComponent} from './sign-in/sign-in.component';
import {ResetPasswordPopup} from "./sign-in/reset-password-popup/reset-password-popup";
import {GoogleRedirectComponent} from "./google-redirect.component";
import {GuardsModule} from "./core/guards/guards.module";
import {InterceptorModule} from "./core/interceptors/interceptor.module";
import {ThemeModule} from "./theme/theme.module";

@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent,
    LogoutComponent,
    GoogleRedirectComponent,
    SignInComponent,
    ResetPasswordPopup
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    InterceptorModule,
    SharedModule,
    GuardsModule,
    ThemeModule.forRoot()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

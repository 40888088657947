import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {Router} from "@angular/router";
import {HTTPContextNoRetry} from "./context/context";

@Injectable()
export class ErrorRequestInterceptor implements HttpInterceptor {

  constructor(
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.context.get(HTTPContextNoRetry)) return next.handle(request)
    return next.handle(request)
      .pipe(retry(1), catchError((error: HttpErrorResponse) => {
          if (error.status === 403) {
            return this.router.navigate(['authorized', 'needSessionRefresh'])
              .then(_ => Promise.reject('Unauthorized'))
          }
          throw new Error(error.message || "server error")
        })
      );
  }
}



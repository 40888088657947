import {Injectable} from "@angular/core";
import {IntrospectionDTO, LoginResponse} from "../api/dto/auth.dto";
import {AuthState} from "../state/auth.state";
import {AuthApi} from "../api/auth.api";
import {FacadeModule} from "./facade.module";
import {Observable} from "rxjs";


@Injectable({providedIn: FacadeModule})
export class AuthFacade {

  private refreshTokenPromise?: Promise<LoginResponse> = undefined

  constructor(
    private authApi: AuthApi,
    private authState: AuthState
  ) {
    const loginString = localStorage.getItem('login')
    if (loginString) {
      const login = JSON.parse(loginString)
      this.authState.credential$.next(login)
    }
  }

  public get authID(): string | undefined {
    return this.authState.authID$.value
  }

  public get authID$(): Observable<string | undefined> {
    return this.authState.authID$.asObservable()
  }

  public set authID(id) {
    this.authState.authID$.next(id)
  }

  public get jwt(): string | undefined {
    return this.authState.credential$.value?.access_token
  }

  public logout() {
    localStorage.clear()
    this.authState.credential$.next(undefined)
    this.refreshTokenPromise = undefined
  }

  public async loginWithRefreshToken(): Promise<void> {
    if (!this.refreshTokenPromise) {
      try {
        const refreshToken = this.authState.credential$.value!.refresh_token
        const request = this.authApi.loginWithRefreshToken(refreshToken)
        this.refreshTokenPromise = request
        const response = await request
        const jsonString = JSON.stringify(response);
        localStorage.setItem('login', jsonString);
        this.authState.credential$.next(response);
        this.refreshTokenPromise = undefined;
        // return response;
      } catch (error) {
        this.authState.credential$.next(undefined);
        localStorage.clear()
        throw error
      }
    }
    // return this.refreshTokenPromise;
  }

  //FIXME: Documentare il ritorno `<IntrospectionDTO & T>`
  public async introspect<T>(token: string): Promise<IntrospectionDTO & T> {
    return await this.authApi.introspect(token)
  }

  public setLoggedIn(login: LoginResponse): LoginResponse {
    const jsonString = JSON.stringify(login);
    localStorage.setItem('login', jsonString);
    this.authState.credential$.next(login);
    return login;
  }

  public get getNavigateTo(): any | undefined {
    const route = localStorage.getItem('navigateTo')
    if (!route) return undefined
    localStorage.removeItem('navigateTo')
    return JSON.parse(route)
  }
}

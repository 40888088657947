<div class=" h-100 overflow-scroll">
  <div style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
    <div class="text-center">
      <fa-icon class="opacity-50" icon="ufo-beam" size="5x"></fa-icon>
      <h3 class="mt-5" i18n>Gentile utente, <br> Siamo spiacenti ma la risorsa cercata risulta inesistente!</h3>
    </div>
  </div>

  <div style="position: fixed; top: 95%; left: 50%; transform: translate(-50%, -50%);">
    <small class="me-1" i18n>Powered by</small>
    <img alt="FlowPay srl" class="footer-logo" src="assets/logo-FP.svg">
  </div>
</div>

import {Component} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {LoggerService} from "./core/logger.service";


@Component({
  selector: 'app-google-redirect',
  template: ''
})
export class GoogleRedirectComponent {

  private readonly route: ActivatedRoute;

  constructor(route: ActivatedRoute, private logger: LoggerService) {
    this.route = route;
    this.logger.debug({route: this.route})
    route.fragment.subscribe(fragment => {
      if (fragment?.includes('id_token=')) {
        const idTokenStart = fragment.indexOf('id_token=') + 'id_token='.length
        const idTokenEnd = fragment.indexOf('&', idTokenStart)
        const idToken = fragment.substring(idTokenStart, idTokenEnd != -1 ? idTokenEnd : undefined)
        window.localStorage.setItem('google_id', idToken);
        window.close()
      }
    })
  }
}

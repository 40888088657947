import {Injectable, TemplateRef} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ToasterService {
  public toasts: any[] = [];

  constructor() {
  }

  show(textOrTpl: string | TemplateRef<any>, status?: 'success' | 'warning' | 'danger' | 'info' | 'primary' | 'basic', delay?: number) {
    status = status ? status : 'success'
    delay = delay ? delay : 5000

    const options = {
      classname: `bg-${status} text-light`,
      delay: delay,
    }
    this.toasts.push({textOrTpl, ...options});
  }

  showToast(textOrTpl: string | TemplateRef<any>, status?: 'success' | 'warning' | 'danger' | 'info' | 'primary' | 'basic', delay?: number) {
    this.show(textOrTpl, status, delay)
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FacadeModule} from "../facade/facade.module";

@NgModule({
  imports: [
    CommonModule,
    FacadeModule,
  ],
})
export class GuardsModule {
}

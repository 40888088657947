import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {AppleSignInBody, GoogleSignInRequest, LoginResponse} from "../core/api/dto/auth.dto";
import {lastValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SignInApi {
  private baseUrl: string = environment.apiBaseURL + '/credentials';

  constructor(private http: HttpClient) {
  }

  public loginWithEmailAndPassword(email: string, password: string): Promise<LoginResponse> {
    const url = `${this.baseUrl}/login`
    const payload: EmailPasswordDTO = {email, password}
    const request = this.http.post<LoginResponse>(url, payload)
    return lastValueFrom(request)
  }

  public resendEmailConfirmation(email: string): Promise<void> {
    const url = `${this.baseUrl}/confirmation/send`
    let params = new HttpParams();
    params = params.append('email', email);

    const request = this.http.post<void>(url, null, {params})
    return lastValueFrom(request)
  }

  public resetPasswordUsingMail(email: string): Promise<void> {
    const url = `${this.baseUrl}/reset`
    const payload: ResetEmailRequest = {email};
    const request = this.http.post<void>(url, payload)
    return lastValueFrom(request)
  }

  public resetPassword(password: string, code: string): Promise<void> {
    const url = `${this.baseUrl}/reset/${code}`
    const payload: ResetPasswordRequest = {password};
    const request = this.http.post<void>(url, payload)
    return lastValueFrom(request)
  }

  public loginWithApple(data: AppleSignInBody): Promise<LoginResponse> {
    const url = `${this.baseUrl}/apple`
    const request = this.http.post<LoginResponse>(url, data)
    return lastValueFrom(request)
  }

  public loginWithGoogle(idToken: string): Promise<LoginResponse> {
    const url = `${this.baseUrl}/google`
    const body: GoogleSignInRequest = {idToken}
    const request = this.http.post<LoginResponse>(url, body)
    return lastValueFrom(request)
  }

}

interface ResetEmailRequest {
  email: string;
}

interface ResetPasswordRequest {
  password: string;
}


interface EmailPasswordDTO {
  email: string;
  password: string;
}

import {Component, OnInit} from "@angular/core";
import {environment} from "src/environments/environment";


@Component({
  template: '',
  selector: 'app-redirect'
})
export class RedirectComponent implements OnInit {
  ngOnInit() {
    window.location.href = environment.platformFrontend
  }
}

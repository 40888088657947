import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable()
export class MockInterceptor implements HttpInterceptor {

  basePath = 'assets/mock';

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes(environment.apiBaseURL) && !request.url.includes(environment.platformApiURL)) return next.handle(request)

    let path: string
    let url = request.url
    url = url.replace(environment.apiBaseURL, '')
    url = url.replace(environment.platformApiURL, '')
    path = this.basePath + url + '+' + request.method + '.json';
    const mockRequest = new HttpRequest("GET", path, request.body);
    return next.handle(mockRequest);
  }
}

import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";


@Component({
  template: '',
  selector: 'app-logout'
})
export class LogoutComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    window.localStorage.clear();
    this.activatedRoute.queryParams.subscribe(params => {
      const url = params['redirect']
      if (url) window.location.href = url
    })
  }
}

import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AppleSignInBody } from 'src/app/core/api/dto/auth.dto';

declare const AppleID: any;

@Component({
  selector: 'app-apple-login',
  styleUrls: ['./apple-sso.component.scss'],
  templateUrl: './apple-sso.component.html'
})
export class AppleSSOComponent implements OnInit, AfterViewChecked {

  @Input() mode: 'sign-in' | 'sign-up' = 'sign-in'
  @Output() login: EventEmitter<AppleSignInBody> = new EventEmitter<AppleSignInBody>();

  private redirectURL: string = window.location.origin;

  constructor() {
  }

  ngOnInit() {
    document.addEventListener('AppleIDSignInOnSuccess', (success) => this.loginSucceeded(success));
    document.addEventListener('AppleIDSignInOnFailure', (error) => this.loginFailed(error));
  }

  ngAfterViewChecked(): void {
    const state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const nonce = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    AppleID.auth.init({
      clientId: "com.flowpayapp.platform",
      scope: "name email",
      redirectURI: this.redirectURL,
      state,
      nonce,
      usePopup: true
    });
  }

  private loginSucceeded(success: any) {
    this.login.emit(success.detail);
  }

  private loginFailed(error: Event) {
    console.log({error});
  }
}

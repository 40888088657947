import {Component, OnInit} from '@angular/core';
import {LoggerService} from "./core/logger.service";

@Component({
  selector: 'app-root',
  template: `
    <ngx-spinner #spinnerElement
                 bdColor="rgba(243, 248, 252, 1.0)"
                 color="#A961EC"
                 name="appSpinner"
                 size="large"
                 [fullScreen]="true"
                 type="ball-grid-pulse"
    ></ngx-spinner>
    <router-outlet></router-outlet>
    <app-toast aria-atomic="true" aria-live="polite"></app-toast>
  `,
})
export class AppComponent implements OnInit {

  constructor(
    private logger: LoggerService
  ) {
  }

  ngOnInit() {
    this.logger.debug('Init app component')
  }

}

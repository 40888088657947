import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiModule} from "../api/api.module";
import {StateModule} from "../state/state.module";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ApiModule,
    StateModule
  ],
})
export class FacadeModule {
}

<!--<loading-spinner [show]="loading"></loading-spinner>-->

<div *ngIf="!loading" class="container-fluid"
     style="background: linear-gradient(180deg, #F3F8FC 0%, rgba(243, 248, 252, 0.3) 100%);">
  <div class="row">
    <div class="col-4 m-auto text-center d-none d-md-block">
      <img alt="" src="/assets/Logo-login.svg" style="width: 90%; height: auto">
    </div>
    <div class="col px-0">

      <div class="vh-100 flex-fill px-4 py-4 bg-white" style="overflow-x: hidden; overflow-y: auto;">

        <div class="d-flex align-items-center flex-column h-100">
          <h3 class="text-center mb-3" i18n>Accedi ai servizi FlowPay</h3>


          <form (keydown)="keyDownFunction($event)" class="col-11 col-md-6 mb-3">

            <div class="form-group mb-3">
              <label class="form-label fw-semibold" for="emailInput" i18n style="color: #0C2E52;"> Email </label>
              <input [(ngModel)]="email" [ngModelOptions]="{standalone: true}" class="form-control" i18n i18n-placeholder
                     id="emailInput"
                     pattern=".+@.+\..+" placeholder="mario.rossi@emailexample.com" required
                     type="email">
            </div>

            <div class="form-group mb-3">
              <label class="form-label fw-semibold" for="passwordInput" i18n style="color: #0C2E52;"> Password </label>
              <input [(ngModel)]="password" [ngModelOptions]="{standalone: true}" class="form-control" i18n i18n-placeholder
                     id="passwordInput"
                     placeholder="Password" required
                     type="password">
            </div>

            <div class="w-100 text-end">
              <button (click)="openResetPasswordDialog()" class="btn btn-link text-decoration-underline"
                      i18n type="button"> Password dimenticata?
              </button>
            </div>

            <button (click)="login()" class="w-100 mt-3 btn btn-primary w-100" i18n>
              Accedi
            </button>
          </form>

          <app-apple-login (login)="appleLogin($event)" *ngIf="disclaimer && canRegister" class="mt-3"
                           mode="sign-in"></app-apple-login>
          <app-google-login (logged)="googleLogin($event)" *ngIf="disclaimer && canRegister"
                            class="mt-3"></app-google-login>

          <p *ngIf="canRegister" class="mt-5 text-center col col-md-10" i18n style="font-size: smaller;">
            Se clicchi "Accedi con Apple" o "Accedi con Google" ti registri e accetti i
            <a [href]="disclaimer?.url || ''" target="_blank">Termini e condizioni di utilizzo </a> e la
            <a href="https://app.flowpay.it/privacy" target="_blank">Privacy Policy</a>
            di FlowPay.
          </p>

          <div *ngIf="canRegister" class="text-center mt-auto" i18n>Non hai ancora un account?</div>
          <button (click)="register()" *ngIf="canRegister"
                  class="btn btn-link text-decoration-underline fw-bold mx-auto" i18n>Registrati
            subito!
          </button>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #resetPasswordPopup>
  <app-send-password-reset-popup (cancel)="closeReset()" (done)="doneReset()"></app-send-password-reset-popup>
</ng-template>

import {Injectable} from "@angular/core";
import {SignInApi} from "./sign-in.api";
import {AuthState} from "../core/state/auth.state";
import {AppleSignInBody, LoginResponse} from "../core/api/dto/auth.dto";


@Injectable({providedIn: 'root'})
export class SignInFacade {


  constructor(
    private signInApi: SignInApi,
    private authState: AuthState
  ) {
    const loginString = localStorage.getItem('login')
    if (loginString) {
      const login = JSON.parse(loginString)
      this.authState.credential$.next(login)
    }
  }


  public async loginWithEmailAndPassword(email: string, password: string): Promise<LoginResponse> {
    return await this.signInApi.loginWithEmailAndPassword(email, password)
  }

  public async resendEmailConfirmation(email: string): Promise<void> {
    return await this.signInApi.resendEmailConfirmation(email)
  }

  public async resetPasswordUsingMail(email: string): Promise<void> {
    return await this.signInApi.resetPasswordUsingMail(email)
  }

  public async loginWithApple(data: AppleSignInBody): Promise<LoginResponse> {
    return await this.signInApi.loginWithApple(data)
  }

  public async loginWithGoogle(idToken: string): Promise<LoginResponse> {
    return await this.signInApi.loginWithGoogle(idToken)
  }

}

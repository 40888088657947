import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {RedirectComponent} from './redirect.component';
import {LogoutComponent} from "./logout.component";
import {SignInComponent} from "./sign-in/sign-in.component";
import {GoogleRedirectComponent} from "./google-redirect.component";
import {environment} from "../environments/environment";

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: 'sign-in',
        component: SignInComponent
      },
      {
        path: 'sign-in/:authorizationID',
        component: SignInComponent
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
      {
        path: 'redirect',
        component: RedirectComponent
      },
      {
        path: 'google/redirect',
        component: GoogleRedirectComponent
      },
      {
        path: 'onboarding',
        loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
      },
      {
        path: 'onboarding/:authorizationID',
        loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
      },
      {
        path: 'authorized',
        loadChildren: () => import('./authorized/authorized.module').then(m => m.AuthorizedModule)
      },
      {path: 'invite/user/:session', redirectTo: 'authorized/invite/user/:session'},
      {path: 'reset-password', redirectTo: 'authorized/reset-password'},
      {path: '**', redirectTo: 'sign-in'}
    ]
  }
];

const config: ExtraOptions = {
  useHash: false,
  initialNavigation: 'enabledBlocking',
  enableTracing: !environment.production,
  paramsInheritanceStrategy: 'always'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
// import {NgxSpinnerService} from "ngx-spinner";


@Component({
  selector: 'loading-spinner',
  templateUrl: './loading-spinner.component.html'
})
export class LoadingSpinnerComponent implements OnInit, OnChanges {

  @Input() message?: string = $localize`Caricamento`

  constructor(
  ) {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    //this.show ? await this.spinner.show('loadingSpinner') : await this.spinner.hide('loadingSpinner')
  }

  async ngOnInit(): Promise<void> {
    //this.show ? await this.spinner.show('loadingSpinner') : await this.spinner.hide('loadingSpinner')
  }
}

import {CommonModule} from "@angular/common";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {NgModule} from "@angular/core";
import {ThemeModule} from "src/app/theme/theme.module";
import {ApiRequestInterceptor} from "./api-request-interceptor.service";
import {AngularDateHttpInterceptor} from "./date.interceptor";
import {environment} from '../../../environments/environment';
import {MockInterceptor} from "./mock-interceptor";
import {ErrorRequestInterceptor} from "./error-interceptor";


const productionInterceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiRequestInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorRequestInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AngularDateHttpInterceptor,
    multi: true,
  }
];

const developmentInterceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MockInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AngularDateHttpInterceptor,
    multi: true,
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ThemeModule
  ],
  providers: environment.useMock ? developmentInterceptors : productionInterceptors
})
export class InterceptorModule {
}

import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {catchError, retry} from "rxjs/operators";
import {AuthFacade} from "../facade/auth.facade";


@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {

  constructor(private authFacade: AuthFacade) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwt = this.authFacade.jwt
    let httpHeaders = request.headers;
    if (jwt)  httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + jwt);
    const cloned = request.clone({headers: httpHeaders});
    return next.handle(cloned).pipe(retry(1), catchError((error: HttpErrorResponse) => {
      return throwError(error.error)
    }));
  }

}

<div class="p-4 d-flex row center">
  <div class="card-title text-center" i18n>
    Inserisci la tua email. <br> Ti arriverà un link attraverso il quale reimpostare la tua password.
  </div>
  <div class="card-body">
    <input [(ngModel)]="email" class="form-control" i18n-placeholder pattern=".+@.+\..+" placeholder="Email">
  </div>
  <div>
    <div class="row my-3">
      <button (click)="reset()" [disabled]="isDisabled" class="ml-auto btn btn-primary" i18n>Invia</button>
    </div>
    <div class="row">
      <button (click)="cancel.emit()" class="ml-auto btn btn-outline-primary" i18n>Chiudi</button>
    </div>

  </div>
</div>

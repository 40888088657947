import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ThemeModule} from "../theme/theme.module";
import {LoadingSpinnerComponent} from './components/loading-spinner/loading-spinner.component';
import {ToastComponent} from './components/toast/toast.component';
import {NotFoundComponent} from "./components/not-found/not-found.component";
import {GoogleSignInComponent} from "./components/google-login/google-sign-in.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {AppleSSOComponent} from "./components/apple-sso/apple-sso.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

const SHARED_COMPONENTS = [
  LoadingSpinnerComponent,
  AppleSSOComponent,
  GoogleSignInComponent,
  ToastComponent,
  NotFoundComponent,
];


@NgModule({
  declarations: [...SHARED_COMPONENTS],
  imports: [
    CommonModule,
    ThemeModule,
    NgSelectModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule
  ],
  exports: [...SHARED_COMPONENTS]
})
export class SharedModule {
}

import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {DisclaimerFacade} from "../core/facade/disclaimer.facade";
import {ToasterService} from "../core/toaster.service";
import {LoggerService} from "../core/logger.service";
import {SignInFacade} from "./sign-in.facade";
import {Disclaimer} from "@flowpay/flow-ui";
import {AppleSignInBody} from "../core/api/dto/auth.dto";
import {AuthFacade} from "../core/facade/auth.facade";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  public email: string = ""
  public password: string = ""
  public disclaimer?: Disclaimer;
  public loading: boolean = true;

  private authID?: string;
  private resetPasswordPopup?: NgbModalRef;
  @ViewChild('resetPasswordPopup') private resetPasswordPopupElement?: TemplateRef<any>;
  public canRegister: boolean = true;

  constructor(
    private signInFacade: SignInFacade,
    private authFacade: AuthFacade,
    private toasterService: ToasterService,
    private disclaimerFacade: DisclaimerFacade,
    private activatedRoute: ActivatedRoute,
    private dialogService: NgbModal,
    private router: Router,
    private logger: LoggerService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.loading = true
    const appClientID =  this.activatedRoute.snapshot.queryParamMap.get('client_id')
    this.canRegister = appClientID != environment.APP_CLIENT_ID

    try {
      await this.disclaimerFacade.get()
      this.disclaimer = this.disclaimerFacade.disclaimer
      this.loading = false
      const authID = this.activatedRoute.snapshot.paramMap.get('authorizationID')
      if (!!authID) {
        this.authID = authID
        this.authFacade.authID = authID
      }

    } catch (error) {
      this.logger.debug({error})
    }
  }

  public async login() {
    if (!this.email || !this.password) {
      await this.toasterService.showToast($localize`Email o Password mancanti`, 'warning')
      return
    }
    try {
      const response = await this.signInFacade.loginWithEmailAndPassword(this.email, this.password)
      this.authFacade.setLoggedIn(response)
      const route = this.authFacade.getNavigateTo

      if (route) { await this.router.navigate(route) }
      else if (!!this.authID) await this.router.navigate(['authorized','oauth',this.authID])
    } catch (error) {
      this.toasterService.showToast($localize`Credenziali errate o non trovate`, 'warning')
      this.logger.debug({error})
    }
  }

  public async appleLogin(login: AppleSignInBody) {
    localStorage.clear()
    try {
      const response = await this.signInFacade.loginWithApple(login)
      this.authFacade.setLoggedIn(response)
      if (this.authFacade.getNavigateTo) await this.router.navigate([this.authFacade.getNavigateTo])
    } catch (error) {
      this.logger.debug({error})
      await this.router.navigate(['onboarding'])
    }
  }

  public async googleLogin(token: string) {
    localStorage.clear()
    try {
      const response = await this.signInFacade.loginWithGoogle(token)
      this.authFacade.setLoggedIn(response)
      if (this.authFacade.getNavigateTo) await this.router.navigate([this.authFacade.getNavigateTo])
    } catch (error) {
      this.logger.debug({error})
      await this.router.navigate(['onboarding', 'company'])
      window.location.reload()
    }
  }

  public openResetPasswordDialog() {
    if (this.resetPasswordPopupElement) {
      this.resetPasswordPopup = this.dialogService.open(this.resetPasswordPopupElement);
    }
  }

  public doneReset() {
    this.toasterService.showToast($localize`Email inviata`)
    if (this.resetPasswordPopup) this.resetPasswordPopup.close();
  }

  public async keyDownFunction(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.email && this.password) await this.login()
  }

  public async register() {
    await this.router.navigate(['onboarding', 'company', 'ais'])
  }

  public closeReset() {
    if (this.resetPasswordPopup) this.resetPasswordPopup.close();
  }
}

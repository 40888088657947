// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  scaEnabled: true,
  useMock: false,
  apiBaseURL: "/api/openid",
  // apiBaseURL: "https://core.c2c-development.flowpay.it/api/openid",
  googleClientID: "1057851009349-q9k9khutka7hmsfas87052870karhr8o.apps.googleusercontent.com",
  platformApiURL: "https://core.c2c-development.flowpay.it/api",
  platformFrontend: "https://flowpay.it",
  sandbox: false,
  APP_CLIENT_ID: '6264eaa2-2a0d-496b-a22d-77ba76b0a405',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {LoginResponse} from "../api/dto/auth.dto";
import {StateModule} from "./state.module";

@Injectable({providedIn: StateModule})
export class AuthState {
  public credential$: BehaviorSubject<LoginResponse | undefined> = new BehaviorSubject<LoginResponse | undefined>(undefined);
  public authID$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
}

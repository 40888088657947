import {Injectable} from "@angular/core";
import {DisclaimerState} from "../state/disclaimer.state";
import {Observable} from "rxjs";
import {DisclaimerApi} from "../api/disclaimer.api";
import {FacadeModule} from "./facade.module";
import {Disclaimer} from "@flowpay/flow-ui";

@Injectable({providedIn: FacadeModule})
export class DisclaimerFacade {

  constructor(
    private disclaimerApi: DisclaimerApi,
    private disclaimerState: DisclaimerState
  ) {
  }

  public get disclaimer$(): Observable<Disclaimer | undefined> {
    return this.disclaimerState.disclaimer$
  }

  public get disclaimer(): Disclaimer | undefined {
    return this.disclaimerState.disclaimer$.value
  }

  public async get(): Promise<void> {
    if (!!this.disclaimerState.disclaimer$.value) return
    const response = await this.disclaimerApi.get()
    return this.disclaimerState.disclaimer$.next(response)
  }

  public async accept(disclaimerID: string): Promise<void> {
    const response = await this.disclaimerApi.accept(disclaimerID)
    return this.disclaimerState.disclaimer$.next(response)
  }
}

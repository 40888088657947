<div
  *ngIf="mode == 'sign-in'"
  data-border-radius="8"
  data-height="44"
  data-mode="center-align"
  data-type="sign-in"
  data-width="250"
  id="appleid-signin"
></div>
<div
  *ngIf="mode == 'sign-up'"
  data-border-radius="8"
  data-height="44"
  data-mode="center-align"
  data-type="sign-up"
  data-width="250"
  id="appleid-signup"
></div>

import {Injectable} from "@angular/core";
import {environment} from "src/environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http"
import {lastValueFrom} from "rxjs";
import {IntrospectionDTO, LoginResponse, RefreshTokenDTO, RegisterDTO} from "./dto/auth.dto";
import {ApiModule} from "./api.module";


@Injectable({providedIn: ApiModule})
export class AuthApi {
  private baseUrl: string = `${environment.apiBaseURL}/credentials`

  constructor(
    private http: HttpClient
  ) {
  }

  public registerWithEmailAndPassword(email: string, password: string, tos: boolean): Promise<void> {
    const url = this.baseUrl + '/register';
    const payload: RegisterDTO = {email, password, tos};
    const request = this.http.post<void>(url, payload)
    return lastValueFrom(request)
  }

  public async loginWithRefreshToken(refreshToken: string): Promise<LoginResponse> {
    const url = this.baseUrl + '/login/refresh'
    const payload: RefreshTokenDTO = {refreshToken}
    const request = this.http.post<LoginResponse>(url, payload)
    return lastValueFrom(request)
  }

  //FIXME: Documentare il ritorno `<IntrospectionDTO & T>`
  public introspect<T>(token: string): Promise<IntrospectionDTO & T> {
    const url = `${this.baseUrl}/introspection`
    const body = new HttpParams().set('token', token)
    const request = this.http.post<IntrospectionDTO & T>(url, body)
    return lastValueFrom(request)
  }

}

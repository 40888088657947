import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {lastValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {ApiModule} from './api.module';
import {Disclaimer} from "@flowpay/flow-ui";

@Injectable({providedIn: ApiModule})
export class DisclaimerApi {
  baseUrl: string =   environment.apiBaseURL + '/disclaimer';

  constructor(
    private http: HttpClient
  ) { }

  public get(): Promise<Disclaimer> {
    const url = this.baseUrl
    const request = this.http.get<Disclaimer>(url)
    return lastValueFrom(request)
  }

  public accept(disclaimerID: string): Promise<Disclaimer> {
    const url = `${this.baseUrl}/${disclaimerID}`;
    const request = this.http.put<Disclaimer>(url, null)
    return lastValueFrom(request)
  }

}

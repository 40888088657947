import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ImageCropperModule} from "ngx-image-cropper";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {
  FpyBankDetailModule,
  FpyBankSelectionModule,
  FpyConsentsScaModule, FpyImageCropperModule,
  LineMiddleTextModule
} from "@flowpay/flow-ui";

const FLOW_UI = [
  FpyBankSelectionModule,
  FpyBankDetailModule,
  FpyConsentsScaModule,
  LineMiddleTextModule,
  FpyImageCropperModule
]

const MODULES = [
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  ImageCropperModule,
  NgxSpinnerModule,
  NgbModule,
  FontAwesomeModule
];

@NgModule({
  imports: [...MODULES, ...FLOW_UI],
  exports: [...MODULES, ...FLOW_UI],
  declarations: [],
  providers: []
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [],

    } as ModuleWithProviders<ThemeModule>;
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Subject, Subscription} from 'rxjs';

@Component({
  selector: 'app-google-login',
  templateUrl: './google-sign-in.component.html',
  styleUrls: ['./google-sign-in.component.scss']
})
export class GoogleSignInComponent implements OnInit {

  @Input() buttonText = $localize`Accedi con Google`;
  @Output() logged: EventEmitter<string> = new EventEmitter<string>();

  public hadErrorInitializing: boolean = false
  private userLogged: Subject<string> = new Subject()
  private subscription?: Subscription

  constructor() {
  }

  ngOnInit() {
    gapi.load('auth2', () =>  this.initButton())
  }

  public login(event: MouseEvent) {
    if (this.hadErrorInitializing) {
      event.stopPropagation()
      this.googleOauthFlow()
    } else {
      this.subscription?.unsubscribe()
      this.subscription = this.userLogged.subscribe(login => {
        if (login) {
          this.logged.emit(login)
          this.subscription?.unsubscribe()
        }
      })
    }
  }

  private googleOauthFlow() {
    const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${environment.googleClientID}&scope=openid profile email&redirect_uri=${window.location.origin}/google-redirect&response_type=id_token&nonce=12345&response_mode=query`
    const width = 400
    const height = 600
    const left = (screen.width / 2) - (width / 2);
    const top = (screen.height / 2) - (height / 2);
    window.open(url, '_blank', 'width=' + width + ', height=' +
      height + ', top=' + top + ', left=' + left);
    const handler = (event: StorageEvent) => {
      if (event.key === 'google_id') {
        const token = event.newValue
        window.removeEventListener('storage', handler)
        if (token) this.logged.emit(token)
        window.localStorage.removeItem('google_id')
      }
    }
    window.addEventListener('storage', handler)
  }

  private initButton(): gapi.auth2.GoogleAuth {
    return gapi.auth2.init({
      'client_id': environment.googleClientID,
      'scope': 'profile email'
    }).then(
      () => this.renderButton(),
      () => {
        this.hadErrorInitializing = true
        this.renderButton()
      })
  }

  private renderButton(): void {
    return gapi.signin2.render('my-signin2', {
      'scope': 'profile email',
      'width': 250,
      'height': 44,
      'longtitle': true,
      'theme': 'light',
      'onsuccess': (success) => {
        this.userLogged.next(success.getAuthResponse(true).id_token)
      },
      'onfailure': (error) => console.error(error)
    });

  }


}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SignInFacade} from "../sign-in.facade";

@Component({
  selector: 'app-send-password-reset-popup',
  templateUrl: './reset-password-popup.html',
  styleUrls: ['./reset-password-popup.scss']
})
export class ResetPasswordPopup implements OnInit {
  @Output() done: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  public email?: string

  constructor(private signInFacade: SignInFacade) {
  }

  public get isDisabled() {
    return !this.email || !this.email.match('.+@.+\\..+')
  }

  ngOnInit() {
  }

  public async reset() {
    await this.signInFacade.resetPasswordUsingMail(this.email!)
    this.done.emit()
  }
}
